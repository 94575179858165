import Vue from 'vue'
import Router from 'vue-router'

// Importar páginas
import ArticleList from '~/pages/article_list/_.vue'
import ArticlePag from '~/pages/article_page/_.vue'
import AudiosList from '~/pages/audios_list/_.vue'
import AudiosPage from '~/pages/audios_page/_.vue'
import AuthLogin from '~/pages/auth_login/_.vue'
import AuthCallback from '~/pages/auth_callback/_.vue'
import AuthRedirect from '~/pages/auth_redirect/_.vue'
import AuthRemember from '~/pages/auth_remember/_.vue'
import AuthData from '~/pages/auth_data/_.vue'
import AuthNewsletter from '~/pages/auth_newsletter/_.vue'
import AuthPassword from '~/pages/auth_password/_.vue'
import AuthSignin from '~/pages/auth_signin/_.vue'
import CountriesRedirect from '~/pages/countries_redirect/_.vue'
import AuthorList from '~/pages/author_list/_.vue'
import BrandList from '~/pages/brand_list/_.vue'
import Calculator from '~/pages/calculator/_.vue'
import CalculatorMarathon from '~/pages/calculator_marathon/_.vue'
import CalculatorHalfMarathon from '~/pages/calculator_half_marathon/_.vue'
import Calculator10Km from '~/pages/calculator_10_km/_.vue'
import ComparatorPag from '~/pages/comparator_page/_.vue'
import ComparisonPag from '~/pages/comparison_page/_.vue'
import DiscountCodesList from '~/pages/discount_codes_list/_.vue'
import GalleryPag from '~/pages/gallery_page/_.vue'
import Recommender from '~/pages/recommender/_.vue'
import LandingPag from '~/pages/landing_page/_.vue'
import InquiryList from '~/pages/inquiry_list/_.vue'
import InquiryPage from '~/pages/inquiry_page/_.vue'
import PriceList from '~/pages/price_list/_.vue'
import ProductList from '~/pages/product_list/_.vue'
import ProductPag from '~/pages/product_page/_.vue'
import ProductPrices from '~/pages/product_prices/_.vue'
import RedirectingPag from '~/pages/redirecting/_.vue'
import RedirectingUrlPag from '~/pages/redirecting/tracking_url.vue'
import ReviewList from '~/pages/review_list/_.vue'
import OpinionList from '~/pages/opinion_list/_.vue'
import RaceList from '~/pages/race_list/_.vue'
import RacePage from '~/pages/race_page/_.vue'
import StorePage from '~/pages/store_page/_.vue'
import NotFoundPage from '~/pages/not_found_page/_.vue'
import TagPage from '~/pages/tag_page/_.vue'
import TalkPage from '~/pages/talk_page/_.vue'
import UserPage from '~/pages/user_page/_.vue'
import * as scrollFunctions from '~/mixins/scroll.functions.js'



Vue.use(Router)

export function createRouter () {
  return new Router({
    mode: 'history',
    scrollBehavior: (to, from) => {
      scrollFunctions.init()

      if (to.hash) {
        scrollFunctions.set(to.hash)
        return false;
      }

      return { x: 0, y: 0 }
    },
    routes: [
      {
				path:'/utente/',
				component:UserPage
			},
			{
				path:'/articoli/autori/:pag(\\d+)?',
				component:AuthorList,
				props:{"filter":"articles"}
			},
			{
				path:'/articoli/autori/:autor/:pag(\\d+)?',
				component:ArticleList,
				props:{"breadcrumb":[{"text":"autori","href":"/articoli/autori/"}]}
			},
			{
				path:'/articoli/categorie/:categoria/:pag(\\d+)?',
				component:ArticleList,
				props:{"breadcrumb":[{"text":"Articulos","href":"/articoli/"}]}
			},
			{
				path:'/articoli/etichette/:etiqueta/:pag(\\d+)?',
				component:ArticleList,
				props:{"article":"etiqueta","filter":"tag","breadcrumb":[{"text":"etiqueta","href":"/articoli/etiquetas/"}]}
			},
			{
				path:'/articoli/:pag(\\d+)?',
				component:ArticleList,
				props:{"breadcrumb":[{"text":"Articulos","href":"/articoli/"}]}
			},
			{
				path:'/articoli/*index.amp.html',
				component:ArticlePag,
				meta:{"amp":true}
			},
			{
				path:'/articoli/*',
				component:ArticlePag
			},
			{
				path:'/galleria/*.amp.html',
				component:GalleryPag,
				meta:{"amp":true}
			},
			{
				path:'/galleria/*.html',
				component:GalleryPag
			},
			{
				path:'/shoe-finder/index.amp.html',
				component:Recommender,
				meta:{"amp":true}
			},
			{
				path:'/shoe-finder/',
				component:Recommender
			},
			{
				path:'/recensioni/:pag(\\d+)?',
				component:ReviewList,
				props:{"breadcrumb":[{"text":"Recensioni","href":"/recensioni/"}]}
			},
			{
				path:'/recensioni/tester/:autor/:pag(\\d+)?',
				component:ReviewList,
				props:{"breadcrumb":[{"text":"Recensioni","href":"/recensioni/"}]}
			},
			{
				path:'/autori/:pag(\\d+)?',
				component:AuthorList
			},
			{
				path:'/marche/',
				component:BrandList
			},
			{
				path:'/negozio/:tienda/*',
				component:StorePage
			},
			{
				path:'/gare-podistiche/@@races/:pathMatch?',
				component:RaceList,
				props:{"breadcrumb":[{"text":"Gare podistiche","href":"/gare-podistiche/@@races/"}]}
			},
			{
				path:'/gare-podistiche/@@races/*',
				component:RaceList,
				props:{"breadcrumb":[{"text":"Gare podistiche","href":"/gare-podistiche/@@races/"}]}
			},
			{
				path:'/gare-podistiche/*/iscrizione.html',
				component:RacePage,
				props:{"tipo":"inscriptions","breadcrumb":[{"text":"Gare podistiche","href":"/gare-podistiche/@@races/"}]}
			},
			{
				path:'/gare-podistiche/*/itinerario.html',
				component:RacePage,
				props:{"tipo":"route","breadcrumb":[{"text":"Gare podistiche","href":"/gare-podistiche/@@races/"}]}
			},
			{
				path:'/gare-podistiche/*/verifica.html',
				component:RacePage,
				props:{"tipo":"verify","breadcrumb":[{"text":"Gare podistiche","href":"/gare-podistiche/@@races/"}]}
			},
			{
				path:'/gare-podistiche/*/forum.html',
				component:RacePage,
				props:{"tipo":"forum","breadcrumb":[{"text":"Gare podistiche","href":"/gare-podistiche/@@races/"}]}
			},
			{
				path:'/gare-podistiche/*',
				component:RacePage,
				props:{"tipo":"","breadcrumb":[{"text":"Gare podistiche","href":"/gare-podistiche/@@races/"}]}
			},
			{
				path:'/*/consigli/*-:id.html',
				component:InquiryPage,
				props:{"prodTypeId":"1","breadcrumb":[{"text":"/Consigli","href":"/scarpe-running/consigli/tutti/"}]}
			},
			{
				path:'/*/consigli/*/',
				component:InquiryList,
				props:{"breadcrumb":[{"text":"/Consigli","href":"/scarpe-running/consigli/tutti/"}],"sections":"@@custom"}
			},
			{
				path:'/commenti/articulos/*.html',
				component:TalkPage,
				props:{"breadcrumb":[{"text":"Comentarios","href":"/commenti/articulos/"}]}
			},
			{
				path:'/cercatore/modelli/tutti/*/:q?',
				component:ProductList,
				props:{"prodTypeId":"0","breadcrumb":[{"text":"modelli","href":"/cercatore/modelli/tutti/"}]}
			},
			{
				path:'/cercatore/modelli/:marca/*/:q?',
				component:ProductList,
				props:{"prodTypeId":"0","breadcrumb":[{"text":"modelli","href":"/cercatore/modelli/tutti/"}]}
			},
			{
				path:'/scarpe/*/tag/:pag(\\d+)?',
				component:TagPage,
				props:{"typeId":"1,50,62,64,84"}
			},
			{
				path:'/scarpe/confronto/',
				component:ComparatorPag,
				props:{"prodTypeId":"1,50,62,64,84"}
			},
			{
				path:'/scarpe/comparazione/:prd1/:prd2/*.html',
				component:ComparisonPag,
				props:{"prodTypeId":"1,50,62,64,84"}
			},
			{
				path:'/scarpe/consigli/raccomandazione/*',
				component:InquiryList,
				props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Scarpe","href":"/scarpe/modelli/tutti/"}],"Type":1}
			},
			{
				path:'/commenti/scarpe/*.html',
				component:TalkPage,
				props:{"breadcrumb":[{"text":"Comentarios","href":"/commenti/scarpe/"}]}
			},
			{
				path:'/scarpe/recensioni/tester/:pag(\\d+)?',
				component:AuthorList,
				props:{"filter":"reviews","prodTypeId":"1,50,62,64,84"}
			},
			{
				path:'/scarpe/recensioni/tester/:autor/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Reviews","href":"/recensioni/"}]}
			},
			{
				path:'/scarpe/recensioni/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Reviews","href":"/recensioni/"}]}
			},
			{
				path:'/scarpe/opinioni/tutti/:pag(\\d+)?',
				component:OpinionList,
				props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Opiniones","href":"/opinioni/tutti/"}]}
			},
			{
				path:'/scarpe/modelli/tutti/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Scarpe","href":"/scarpe/modelli/tutti/"}]}
			},
			{
				path:'/scarpe/modelli/:marca/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Scarpe","href":"/scarpe/modelli/tutti/"}]}
			},
			{
				path:'/scarpe/outlet/tutti/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Scarpe","href":"/scarpe/modelli/tutti/"}]}
			},
			{
				path:'/scarpe/outlet/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Scarpe","href":"/scarpe/modelli/tutti/"}]}
			},
			{
				path:'/scarpe/*/amp/prezzi/tutti/*',
				component:ProductPrices,
				props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Scarpe","href":"/scarpe/modelli/tutti/"}]},
				meta:{"amp":true}
			},
			{
				path:'/scarpe/*/prezzi/tutti/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Scarpe","href":"/scarpe/modelli/tutti/"}]}
			},
			{
				path:'/scarpe/*/amp/prezzi/:tienda/*',
				component:ProductPrices,
				props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Scarpe","href":"/scarpe/modelli/tutti/"}]},
				meta:{"amp":true}
			},
			{
				path:'/scarpe/*/prezzi/:tienda/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Scarpe","href":"/scarpe/modelli/tutti/"}]}
			},
			{
				path:'/scarpe/*index.amp.html',
				component:ProductPag,
				props:{"prodTypeId":"1,50,62,64,84"},
				meta:{"amp":true}
			},
			{
				path:'/scarpe/*',
				component:ProductPag,
				props:{"prodTypeId":"1,50,62,64,84"}
			},
			{
				path:'/scarpe-running/*/tag/:pag(\\d+)?',
				component:TagPage,
				props:{"typeId":"1"}
			},
			{
				path:'/scarpe-running/confronto/',
				component:ComparatorPag,
				props:{"prodTypeId":"1"}
			},
			{
				path:'/scarpe-running/comparazione/:prd1/:prd2/*.html',
				component:ComparisonPag,
				props:{"prodTypeId":"1"}
			},
			{
				path:'/scarpe-running/consigli/raccomandazione/*',
				component:InquiryList,
				props:{"prodTypeId":"1","breadcrumb":[{"text":"Scarpe running","href":"/scarpe-running/modelli/tutti/"}],"Type":1}
			},
			{
				path:'/commenti/scarpe-running/*.html',
				component:TalkPage,
				props:{"breadcrumb":[{"text":"Comentarios","href":"/commenti/scarpe-running/"}]}
			},
			{
				path:'/scarpe-running/recensioni/tester/:pag(\\d+)?',
				component:AuthorList,
				props:{"filter":"reviews","prodTypeId":"1"}
			},
			{
				path:'/scarpe-running/recensioni/tester/:autor/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"1","breadcrumb":[{"text":"Reviews","href":"/recensioni/"}]}
			},
			{
				path:'/scarpe-running/recensioni/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"1","breadcrumb":[{"text":"Reviews","href":"/recensioni/"}]}
			},
			{
				path:'/scarpe-running/opinioni/tutti/:pag(\\d+)?',
				component:OpinionList,
				props:{"prodTypeId":"1","breadcrumb":[{"text":"Opiniones","href":"/opinioni/tutti/"}]}
			},
			{
				path:'/scarpe-running/modelli/tutti/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"1","breadcrumb":[{"text":"Scarpe running","href":"/scarpe-running/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-running/modelli/:marca/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"1","breadcrumb":[{"text":"Scarpe running","href":"/scarpe-running/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-running/outlet/tutti/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"1","breadcrumb":[{"text":"Scarpe running","href":"/scarpe-running/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-running/outlet/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"1","breadcrumb":[{"text":"Scarpe running","href":"/scarpe-running/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-running/*/amp/prezzi/tutti/*',
				component:ProductPrices,
				props:{"prodTypeId":"1","breadcrumb":[{"text":"Scarpe running","href":"/scarpe-running/modelli/tutti/"}]},
				meta:{"amp":true}
			},
			{
				path:'/scarpe-running/*/prezzi/tutti/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"1","breadcrumb":[{"text":"Scarpe running","href":"/scarpe-running/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-running/*/amp/prezzi/:tienda/*',
				component:ProductPrices,
				props:{"prodTypeId":"1","breadcrumb":[{"text":"Scarpe running","href":"/scarpe-running/modelli/tutti/"}]},
				meta:{"amp":true}
			},
			{
				path:'/scarpe-running/*/prezzi/:tienda/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"1","breadcrumb":[{"text":"Scarpe running","href":"/scarpe-running/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-running/*index.amp.html',
				component:ProductPag,
				props:{"prodTypeId":"1"},
				meta:{"amp":true}
			},
			{
				path:'/scarpe-running/*',
				component:ProductPag,
				props:{"prodTypeId":"1"}
			},
			{
				path:'/orologi-sportivi/*/tag/:pag(\\d+)?',
				component:TagPage,
				props:{"typeId":"45"}
			},
			{
				path:'/orologi-sportivi/confronto/',
				component:ComparatorPag,
				props:{"prodTypeId":"45"}
			},
			{
				path:'/orologi-sportivi/comparazione/:prd1/:prd2/*.html',
				component:ComparisonPag,
				props:{"prodTypeId":"45"}
			},
			{
				path:'/orologi-sportivi/consigli/raccomandazione/*',
				component:InquiryList,
				props:{"prodTypeId":"45","breadcrumb":[{"text":"Orologi per la corsa","href":"/orologi-sportivi/modelli/tutti/"}],"Type":1}
			},
			{
				path:'/commenti/orologi-sportivi/*.html',
				component:TalkPage,
				props:{"breadcrumb":[{"text":"Comentarios","href":"/commenti/orologi-sportivi/"}]}
			},
			{
				path:'/orologi-sportivi/recensioni/tester/:pag(\\d+)?',
				component:AuthorList,
				props:{"filter":"reviews","prodTypeId":"45"}
			},
			{
				path:'/orologi-sportivi/recensioni/tester/:autor/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"45","breadcrumb":[{"text":"Reviews","href":"/recensioni/"}]}
			},
			{
				path:'/orologi-sportivi/recensioni/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"45","breadcrumb":[{"text":"Reviews","href":"/recensioni/"}]}
			},
			{
				path:'/orologi-sportivi/opinioni/tutti/:pag(\\d+)?',
				component:OpinionList,
				props:{"prodTypeId":"45","breadcrumb":[{"text":"Opiniones","href":"/opinioni/tutti/"}]}
			},
			{
				path:'/orologi-sportivi/modelli/tutti/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"45","breadcrumb":[{"text":"Orologi per la corsa","href":"/orologi-sportivi/modelli/tutti/"}]}
			},
			{
				path:'/orologi-sportivi/modelli/:marca/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"45","breadcrumb":[{"text":"Orologi per la corsa","href":"/orologi-sportivi/modelli/tutti/"}]}
			},
			{
				path:'/orologi-sportivi/outlet/tutti/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"45","breadcrumb":[{"text":"Orologi per la corsa","href":"/orologi-sportivi/modelli/tutti/"}]}
			},
			{
				path:'/orologi-sportivi/outlet/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"45","breadcrumb":[{"text":"Orologi per la corsa","href":"/orologi-sportivi/modelli/tutti/"}]}
			},
			{
				path:'/orologi-sportivi/*/amp/prezzi/tutti/*',
				component:ProductPrices,
				props:{"prodTypeId":"45","breadcrumb":[{"text":"Orologi per la corsa","href":"/orologi-sportivi/modelli/tutti/"}]},
				meta:{"amp":true}
			},
			{
				path:'/orologi-sportivi/*/prezzi/tutti/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"45","breadcrumb":[{"text":"Orologi per la corsa","href":"/orologi-sportivi/modelli/tutti/"}]}
			},
			{
				path:'/orologi-sportivi/*/amp/prezzi/:tienda/*',
				component:ProductPrices,
				props:{"prodTypeId":"45","breadcrumb":[{"text":"Orologi per la corsa","href":"/orologi-sportivi/modelli/tutti/"}]},
				meta:{"amp":true}
			},
			{
				path:'/orologi-sportivi/*/prezzi/:tienda/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"45","breadcrumb":[{"text":"Orologi per la corsa","href":"/orologi-sportivi/modelli/tutti/"}]}
			},
			{
				path:'/orologi-sportivi/*index.amp.html',
				component:ProductPag,
				props:{"prodTypeId":"45"},
				meta:{"amp":true}
			},
			{
				path:'/orologi-sportivi/*',
				component:ProductPag,
				props:{"prodTypeId":"45"}
			},
			{
				path:'/sneakers/*/tag/:pag(\\d+)?',
				component:TagPage,
				props:{"typeId":"50"}
			},
			{
				path:'/sneakers/confronto/',
				component:ComparatorPag,
				props:{"prodTypeId":"50"}
			},
			{
				path:'/sneakers/comparazione/:prd1/:prd2/*.html',
				component:ComparisonPag,
				props:{"prodTypeId":"50"}
			},
			{
				path:'/sneakers/consigli/raccomandazione/*',
				component:InquiryList,
				props:{"prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modelli/tutti/"}],"Type":1}
			},
			{
				path:'/commenti/sneakers/*.html',
				component:TalkPage,
				props:{"breadcrumb":[{"text":"Comentarios","href":"/commenti/sneakers/"}]}
			},
			{
				path:'/sneakers/recensioni/tester/:pag(\\d+)?',
				component:AuthorList,
				props:{"filter":"reviews","prodTypeId":"50"}
			},
			{
				path:'/sneakers/recensioni/tester/:autor/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"50","breadcrumb":[{"text":"Reviews","href":"/recensioni/"}]}
			},
			{
				path:'/sneakers/recensioni/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"50","breadcrumb":[{"text":"Reviews","href":"/recensioni/"}]}
			},
			{
				path:'/sneakers/opinioni/tutti/:pag(\\d+)?',
				component:OpinionList,
				props:{"prodTypeId":"50","breadcrumb":[{"text":"Opiniones","href":"/opinioni/tutti/"}]}
			},
			{
				path:'/sneakers/modelli/tutti/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modelli/tutti/"}]}
			},
			{
				path:'/sneakers/modelli/:marca/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modelli/tutti/"}]}
			},
			{
				path:'/sneakers/outlet/tutti/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modelli/tutti/"}]}
			},
			{
				path:'/sneakers/outlet/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modelli/tutti/"}]}
			},
			{
				path:'/sneakers/*/amp/prezzi/tutti/*',
				component:ProductPrices,
				props:{"prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modelli/tutti/"}]},
				meta:{"amp":true}
			},
			{
				path:'/sneakers/*/prezzi/tutti/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modelli/tutti/"}]}
			},
			{
				path:'/sneakers/*/amp/prezzi/:tienda/*',
				component:ProductPrices,
				props:{"prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modelli/tutti/"}]},
				meta:{"amp":true}
			},
			{
				path:'/sneakers/*/prezzi/:tienda/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modelli/tutti/"}]}
			},
			{
				path:'/sneakers/*index.amp.html',
				component:ProductPag,
				props:{"prodTypeId":"50"},
				meta:{"amp":true}
			},
			{
				path:'/sneakers/*',
				component:ProductPag,
				props:{"prodTypeId":"50"}
			},
			{
				path:'/scarpe-fitness-palestra/*/tag/:pag(\\d+)?',
				component:TagPage,
				props:{"typeId":"62"}
			},
			{
				path:'/scarpe-fitness-palestra/confronto/',
				component:ComparatorPag,
				props:{"prodTypeId":"62"}
			},
			{
				path:'/scarpe-fitness-palestra/comparazione/:prd1/:prd2/*.html',
				component:ComparisonPag,
				props:{"prodTypeId":"62"}
			},
			{
				path:'/scarpe-fitness-palestra/consigli/raccomandazione/*',
				component:InquiryList,
				props:{"prodTypeId":"62","breadcrumb":[{"text":"Scarpe fitness palestra","href":"/scarpe-fitness-palestra/modelli/tutti/"}],"Type":1}
			},
			{
				path:'/commenti/scarpe-fitness-palestra/*.html',
				component:TalkPage,
				props:{"breadcrumb":[{"text":"Comentarios","href":"/commenti/scarpe-fitness-palestra/"}]}
			},
			{
				path:'/scarpe-fitness-palestra/recensioni/tester/:pag(\\d+)?',
				component:AuthorList,
				props:{"filter":"reviews","prodTypeId":"62"}
			},
			{
				path:'/scarpe-fitness-palestra/recensioni/tester/:autor/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"62","breadcrumb":[{"text":"Reviews","href":"/recensioni/"}]}
			},
			{
				path:'/scarpe-fitness-palestra/recensioni/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"62","breadcrumb":[{"text":"Reviews","href":"/recensioni/"}]}
			},
			{
				path:'/scarpe-fitness-palestra/opinioni/tutti/:pag(\\d+)?',
				component:OpinionList,
				props:{"prodTypeId":"62","breadcrumb":[{"text":"Opiniones","href":"/opinioni/tutti/"}]}
			},
			{
				path:'/scarpe-fitness-palestra/modelli/tutti/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"62","breadcrumb":[{"text":"Scarpe fitness palestra","href":"/scarpe-fitness-palestra/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-fitness-palestra/modelli/:marca/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"62","breadcrumb":[{"text":"Scarpe fitness palestra","href":"/scarpe-fitness-palestra/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-fitness-palestra/outlet/tutti/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"62","breadcrumb":[{"text":"Scarpe fitness palestra","href":"/scarpe-fitness-palestra/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-fitness-palestra/outlet/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"62","breadcrumb":[{"text":"Scarpe fitness palestra","href":"/scarpe-fitness-palestra/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-fitness-palestra/*/amp/prezzi/tutti/*',
				component:ProductPrices,
				props:{"prodTypeId":"62","breadcrumb":[{"text":"Scarpe fitness palestra","href":"/scarpe-fitness-palestra/modelli/tutti/"}]},
				meta:{"amp":true}
			},
			{
				path:'/scarpe-fitness-palestra/*/prezzi/tutti/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"62","breadcrumb":[{"text":"Scarpe fitness palestra","href":"/scarpe-fitness-palestra/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-fitness-palestra/*/amp/prezzi/:tienda/*',
				component:ProductPrices,
				props:{"prodTypeId":"62","breadcrumb":[{"text":"Scarpe fitness palestra","href":"/scarpe-fitness-palestra/modelli/tutti/"}]},
				meta:{"amp":true}
			},
			{
				path:'/scarpe-fitness-palestra/*/prezzi/:tienda/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"62","breadcrumb":[{"text":"Scarpe fitness palestra","href":"/scarpe-fitness-palestra/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-fitness-palestra/*index.amp.html',
				component:ProductPag,
				props:{"prodTypeId":"62"},
				meta:{"amp":true}
			},
			{
				path:'/scarpe-fitness-palestra/*',
				component:ProductPag,
				props:{"prodTypeId":"62"}
			},
			{
				path:'/scarpe-crossfit/*/tag/:pag(\\d+)?',
				component:TagPage,
				props:{"typeId":"64"}
			},
			{
				path:'/scarpe-crossfit/confronto/',
				component:ComparatorPag,
				props:{"prodTypeId":"64"}
			},
			{
				path:'/scarpe-crossfit/comparazione/:prd1/:prd2/*.html',
				component:ComparisonPag,
				props:{"prodTypeId":"64"}
			},
			{
				path:'/scarpe-crossfit/consigli/raccomandazione/*',
				component:InquiryList,
				props:{"prodTypeId":"64","breadcrumb":[{"text":"Scarpe crossfit","href":"/scarpe-crossfit/modelli/tutti/"}],"Type":1}
			},
			{
				path:'/commenti/scarpe-crossfit/*.html',
				component:TalkPage,
				props:{"breadcrumb":[{"text":"Comentarios","href":"/commenti/scarpe-crossfit/"}]}
			},
			{
				path:'/scarpe-crossfit/recensioni/tester/:pag(\\d+)?',
				component:AuthorList,
				props:{"filter":"reviews","prodTypeId":"64"}
			},
			{
				path:'/scarpe-crossfit/recensioni/tester/:autor/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"64","breadcrumb":[{"text":"Reviews","href":"/recensioni/"}]}
			},
			{
				path:'/scarpe-crossfit/recensioni/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"64","breadcrumb":[{"text":"Reviews","href":"/recensioni/"}]}
			},
			{
				path:'/scarpe-crossfit/opinioni/tutti/:pag(\\d+)?',
				component:OpinionList,
				props:{"prodTypeId":"64","breadcrumb":[{"text":"Opiniones","href":"/opinioni/tutti/"}]}
			},
			{
				path:'/scarpe-crossfit/modelli/tutti/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"64","breadcrumb":[{"text":"Scarpe crossfit","href":"/scarpe-crossfit/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-crossfit/modelli/:marca/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"64","breadcrumb":[{"text":"Scarpe crossfit","href":"/scarpe-crossfit/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-crossfit/outlet/tutti/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"64","breadcrumb":[{"text":"Scarpe crossfit","href":"/scarpe-crossfit/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-crossfit/outlet/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"64","breadcrumb":[{"text":"Scarpe crossfit","href":"/scarpe-crossfit/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-crossfit/*/amp/prezzi/tutti/*',
				component:ProductPrices,
				props:{"prodTypeId":"64","breadcrumb":[{"text":"Scarpe crossfit","href":"/scarpe-crossfit/modelli/tutti/"}]},
				meta:{"amp":true}
			},
			{
				path:'/scarpe-crossfit/*/prezzi/tutti/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"64","breadcrumb":[{"text":"Scarpe crossfit","href":"/scarpe-crossfit/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-crossfit/*/amp/prezzi/:tienda/*',
				component:ProductPrices,
				props:{"prodTypeId":"64","breadcrumb":[{"text":"Scarpe crossfit","href":"/scarpe-crossfit/modelli/tutti/"}]},
				meta:{"amp":true}
			},
			{
				path:'/scarpe-crossfit/*/prezzi/:tienda/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"64","breadcrumb":[{"text":"Scarpe crossfit","href":"/scarpe-crossfit/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-crossfit/*index.amp.html',
				component:ProductPag,
				props:{"prodTypeId":"64"},
				meta:{"amp":true}
			},
			{
				path:'/scarpe-crossfit/*',
				component:ProductPag,
				props:{"prodTypeId":"64"}
			},
			{
				path:'/scarpe-spinning/*/tag/:pag(\\d+)?',
				component:TagPage,
				props:{"typeId":"66"}
			},
			{
				path:'/scarpe-spinning/confronto/',
				component:ComparatorPag,
				props:{"prodTypeId":"66"}
			},
			{
				path:'/scarpe-spinning/comparazione/:prd1/:prd2/*.html',
				component:ComparisonPag,
				props:{"prodTypeId":"66"}
			},
			{
				path:'/scarpe-spinning/consigli/raccomandazione/*',
				component:InquiryList,
				props:{"prodTypeId":"66","breadcrumb":[{"text":"Scarpe spinning","href":"/scarpe-spinning/modelli/tutti/"}],"Type":1}
			},
			{
				path:'/commenti/scarpe-spinning/*.html',
				component:TalkPage,
				props:{"breadcrumb":[{"text":"Comentarios","href":"/commenti/scarpe-spinning/"}]}
			},
			{
				path:'/scarpe-spinning/recensioni/tester/:pag(\\d+)?',
				component:AuthorList,
				props:{"filter":"reviews","prodTypeId":"66"}
			},
			{
				path:'/scarpe-spinning/recensioni/tester/:autor/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"66","breadcrumb":[{"text":"Reviews","href":"/recensioni/"}]}
			},
			{
				path:'/scarpe-spinning/recensioni/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"66","breadcrumb":[{"text":"Reviews","href":"/recensioni/"}]}
			},
			{
				path:'/scarpe-spinning/opinioni/tutti/:pag(\\d+)?',
				component:OpinionList,
				props:{"prodTypeId":"66","breadcrumb":[{"text":"Opiniones","href":"/opinioni/tutti/"}]}
			},
			{
				path:'/scarpe-spinning/modelli/tutti/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"66","breadcrumb":[{"text":"Scarpe spinning","href":"/scarpe-spinning/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-spinning/modelli/:marca/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"66","breadcrumb":[{"text":"Scarpe spinning","href":"/scarpe-spinning/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-spinning/outlet/tutti/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"66","breadcrumb":[{"text":"Scarpe spinning","href":"/scarpe-spinning/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-spinning/outlet/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"66","breadcrumb":[{"text":"Scarpe spinning","href":"/scarpe-spinning/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-spinning/*/amp/prezzi/tutti/*',
				component:ProductPrices,
				props:{"prodTypeId":"66","breadcrumb":[{"text":"Scarpe spinning","href":"/scarpe-spinning/modelli/tutti/"}]},
				meta:{"amp":true}
			},
			{
				path:'/scarpe-spinning/*/prezzi/tutti/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"66","breadcrumb":[{"text":"Scarpe spinning","href":"/scarpe-spinning/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-spinning/*/amp/prezzi/:tienda/*',
				component:ProductPrices,
				props:{"prodTypeId":"66","breadcrumb":[{"text":"Scarpe spinning","href":"/scarpe-spinning/modelli/tutti/"}]},
				meta:{"amp":true}
			},
			{
				path:'/scarpe-spinning/*/prezzi/:tienda/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"66","breadcrumb":[{"text":"Scarpe spinning","href":"/scarpe-spinning/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-spinning/*index.amp.html',
				component:ProductPag,
				props:{"prodTypeId":"66"},
				meta:{"amp":true}
			},
			{
				path:'/scarpe-spinning/*',
				component:ProductPag,
				props:{"prodTypeId":"66"}
			},
			{
				path:'/smartband/*/tag/:pag(\\d+)?',
				component:TagPage,
				props:{"typeId":"73"}
			},
			{
				path:'/smartband/confronto/',
				component:ComparatorPag,
				props:{"prodTypeId":"73"}
			},
			{
				path:'/smartband/comparazione/:prd1/:prd2/*.html',
				component:ComparisonPag,
				props:{"prodTypeId":"73"}
			},
			{
				path:'/smartband/consigli/raccomandazione/*',
				component:InquiryList,
				props:{"prodTypeId":"73","breadcrumb":[{"text":"Smartband","href":"/smartband/modelli/tutti/"}],"Type":1}
			},
			{
				path:'/commenti/smartband/*.html',
				component:TalkPage,
				props:{"breadcrumb":[{"text":"Comentarios","href":"/commenti/smartband/"}]}
			},
			{
				path:'/smartband/recensioni/tester/:pag(\\d+)?',
				component:AuthorList,
				props:{"filter":"reviews","prodTypeId":"73"}
			},
			{
				path:'/smartband/recensioni/tester/:autor/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"73","breadcrumb":[{"text":"Reviews","href":"/recensioni/"}]}
			},
			{
				path:'/smartband/recensioni/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"73","breadcrumb":[{"text":"Reviews","href":"/recensioni/"}]}
			},
			{
				path:'/smartband/opinioni/tutti/:pag(\\d+)?',
				component:OpinionList,
				props:{"prodTypeId":"73","breadcrumb":[{"text":"Opiniones","href":"/opinioni/tutti/"}]}
			},
			{
				path:'/smartband/modelli/tutti/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"73","breadcrumb":[{"text":"Smartband","href":"/smartband/modelli/tutti/"}]}
			},
			{
				path:'/smartband/modelli/:marca/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"73","breadcrumb":[{"text":"Smartband","href":"/smartband/modelli/tutti/"}]}
			},
			{
				path:'/smartband/outlet/tutti/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"73","breadcrumb":[{"text":"Smartband","href":"/smartband/modelli/tutti/"}]}
			},
			{
				path:'/smartband/outlet/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"73","breadcrumb":[{"text":"Smartband","href":"/smartband/modelli/tutti/"}]}
			},
			{
				path:'/smartband/*/amp/prezzi/tutti/*',
				component:ProductPrices,
				props:{"prodTypeId":"73","breadcrumb":[{"text":"Smartband","href":"/smartband/modelli/tutti/"}]},
				meta:{"amp":true}
			},
			{
				path:'/smartband/*/prezzi/tutti/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"73","breadcrumb":[{"text":"Smartband","href":"/smartband/modelli/tutti/"}]}
			},
			{
				path:'/smartband/*/amp/prezzi/:tienda/*',
				component:ProductPrices,
				props:{"prodTypeId":"73","breadcrumb":[{"text":"Smartband","href":"/smartband/modelli/tutti/"}]},
				meta:{"amp":true}
			},
			{
				path:'/smartband/*/prezzi/:tienda/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"73","breadcrumb":[{"text":"Smartband","href":"/smartband/modelli/tutti/"}]}
			},
			{
				path:'/smartband/*index.amp.html',
				component:ProductPag,
				props:{"prodTypeId":"73"},
				meta:{"amp":true}
			},
			{
				path:'/smartband/*',
				component:ProductPag,
				props:{"prodTypeId":"73"}
			},
			{
				path:'/smartwatch/*/tag/:pag(\\d+)?',
				component:TagPage,
				props:{"typeId":"74"}
			},
			{
				path:'/smartwatch/confronto/',
				component:ComparatorPag,
				props:{"prodTypeId":"74"}
			},
			{
				path:'/smartwatch/comparazione/:prd1/:prd2/*.html',
				component:ComparisonPag,
				props:{"prodTypeId":"74"}
			},
			{
				path:'/smartwatch/consigli/raccomandazione/*',
				component:InquiryList,
				props:{"prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelli/tutti/"}],"Type":1}
			},
			{
				path:'/commenti/smartwatch/*.html',
				component:TalkPage,
				props:{"breadcrumb":[{"text":"Comentarios","href":"/commenti/smartwatch/"}]}
			},
			{
				path:'/smartwatch/recensioni/tester/:pag(\\d+)?',
				component:AuthorList,
				props:{"filter":"reviews","prodTypeId":"74"}
			},
			{
				path:'/smartwatch/recensioni/tester/:autor/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"74","breadcrumb":[{"text":"Reviews","href":"/recensioni/"}]}
			},
			{
				path:'/smartwatch/recensioni/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"74","breadcrumb":[{"text":"Reviews","href":"/recensioni/"}]}
			},
			{
				path:'/smartwatch/opinioni/tutti/:pag(\\d+)?',
				component:OpinionList,
				props:{"prodTypeId":"74","breadcrumb":[{"text":"Opiniones","href":"/opinioni/tutti/"}]}
			},
			{
				path:'/smartwatch/modelli/tutti/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelli/tutti/"}]}
			},
			{
				path:'/smartwatch/modelli/:marca/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelli/tutti/"}]}
			},
			{
				path:'/smartwatch/outlet/tutti/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelli/tutti/"}]}
			},
			{
				path:'/smartwatch/outlet/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelli/tutti/"}]}
			},
			{
				path:'/smartwatch/*/amp/prezzi/tutti/*',
				component:ProductPrices,
				props:{"prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelli/tutti/"}]},
				meta:{"amp":true}
			},
			{
				path:'/smartwatch/*/prezzi/tutti/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelli/tutti/"}]}
			},
			{
				path:'/smartwatch/*/amp/prezzi/:tienda/*',
				component:ProductPrices,
				props:{"prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelli/tutti/"}]},
				meta:{"amp":true}
			},
			{
				path:'/smartwatch/*/prezzi/:tienda/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelli/tutti/"}]}
			},
			{
				path:'/smartwatch/*index.amp.html',
				component:ProductPag,
				props:{"prodTypeId":"74"},
				meta:{"amp":true}
			},
			{
				path:'/smartwatch/*',
				component:ProductPag,
				props:{"prodTypeId":"74"}
			},
			{
				path:'/ciclocomputer/*/tag/:pag(\\d+)?',
				component:TagPage,
				props:{"typeId":"75"}
			},
			{
				path:'/ciclocomputer/confronto/',
				component:ComparatorPag,
				props:{"prodTypeId":"75"}
			},
			{
				path:'/ciclocomputer/comparazione/:prd1/:prd2/*.html',
				component:ComparisonPag,
				props:{"prodTypeId":"75"}
			},
			{
				path:'/ciclocomputer/consigli/raccomandazione/*',
				component:InquiryList,
				props:{"prodTypeId":"75","breadcrumb":[{"text":"Ciclocomputer","href":"/ciclocomputer/modelli/tutti/"}],"Type":1}
			},
			{
				path:'/commenti/ciclocomputer/*.html',
				component:TalkPage,
				props:{"breadcrumb":[{"text":"Comentarios","href":"/commenti/ciclocomputer/"}]}
			},
			{
				path:'/ciclocomputer/recensioni/tester/:pag(\\d+)?',
				component:AuthorList,
				props:{"filter":"reviews","prodTypeId":"75"}
			},
			{
				path:'/ciclocomputer/recensioni/tester/:autor/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"75","breadcrumb":[{"text":"Reviews","href":"/recensioni/"}]}
			},
			{
				path:'/ciclocomputer/recensioni/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"75","breadcrumb":[{"text":"Reviews","href":"/recensioni/"}]}
			},
			{
				path:'/ciclocomputer/opinioni/tutti/:pag(\\d+)?',
				component:OpinionList,
				props:{"prodTypeId":"75","breadcrumb":[{"text":"Opiniones","href":"/opinioni/tutti/"}]}
			},
			{
				path:'/ciclocomputer/modelli/tutti/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"75","breadcrumb":[{"text":"Ciclocomputer","href":"/ciclocomputer/modelli/tutti/"}]}
			},
			{
				path:'/ciclocomputer/modelli/:marca/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"75","breadcrumb":[{"text":"Ciclocomputer","href":"/ciclocomputer/modelli/tutti/"}]}
			},
			{
				path:'/ciclocomputer/outlet/tutti/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"75","breadcrumb":[{"text":"Ciclocomputer","href":"/ciclocomputer/modelli/tutti/"}]}
			},
			{
				path:'/ciclocomputer/outlet/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"75","breadcrumb":[{"text":"Ciclocomputer","href":"/ciclocomputer/modelli/tutti/"}]}
			},
			{
				path:'/ciclocomputer/*/amp/prezzi/tutti/*',
				component:ProductPrices,
				props:{"prodTypeId":"75","breadcrumb":[{"text":"Ciclocomputer","href":"/ciclocomputer/modelli/tutti/"}]},
				meta:{"amp":true}
			},
			{
				path:'/ciclocomputer/*/prezzi/tutti/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"75","breadcrumb":[{"text":"Ciclocomputer","href":"/ciclocomputer/modelli/tutti/"}]}
			},
			{
				path:'/ciclocomputer/*/amp/prezzi/:tienda/*',
				component:ProductPrices,
				props:{"prodTypeId":"75","breadcrumb":[{"text":"Ciclocomputer","href":"/ciclocomputer/modelli/tutti/"}]},
				meta:{"amp":true}
			},
			{
				path:'/ciclocomputer/*/prezzi/:tienda/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"75","breadcrumb":[{"text":"Ciclocomputer","href":"/ciclocomputer/modelli/tutti/"}]}
			},
			{
				path:'/ciclocomputer/*index.amp.html',
				component:ProductPag,
				props:{"prodTypeId":"75"},
				meta:{"amp":true}
			},
			{
				path:'/ciclocomputer/*',
				component:ProductPag,
				props:{"prodTypeId":"75"}
			},
			{
				path:'/scarpe-trekking/*/tag/:pag(\\d+)?',
				component:TagPage,
				props:{"typeId":"84"}
			},
			{
				path:'/scarpe-trekking/confronto/',
				component:ComparatorPag,
				props:{"prodTypeId":"84"}
			},
			{
				path:'/scarpe-trekking/comparazione/:prd1/:prd2/*.html',
				component:ComparisonPag,
				props:{"prodTypeId":"84"}
			},
			{
				path:'/scarpe-trekking/consigli/raccomandazione/*',
				component:InquiryList,
				props:{"prodTypeId":"84","breadcrumb":[{"text":"Scarpe trekking & Scarponi da montagna","href":"/scarpe-trekking/modelli/tutti/"}],"Type":1}
			},
			{
				path:'/commenti/scarpe-trekking/*.html',
				component:TalkPage,
				props:{"breadcrumb":[{"text":"Comentarios","href":"/commenti/scarpe-trekking/"}]}
			},
			{
				path:'/scarpe-trekking/recensioni/tester/:pag(\\d+)?',
				component:AuthorList,
				props:{"filter":"reviews","prodTypeId":"84"}
			},
			{
				path:'/scarpe-trekking/recensioni/tester/:autor/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"84","breadcrumb":[{"text":"Reviews","href":"/recensioni/"}]}
			},
			{
				path:'/scarpe-trekking/recensioni/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"84","breadcrumb":[{"text":"Reviews","href":"/recensioni/"}]}
			},
			{
				path:'/scarpe-trekking/opinioni/tutti/:pag(\\d+)?',
				component:OpinionList,
				props:{"prodTypeId":"84","breadcrumb":[{"text":"Opiniones","href":"/opinioni/tutti/"}]}
			},
			{
				path:'/scarpe-trekking/modelli/tutti/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"84","breadcrumb":[{"text":"Scarpe trekking & Scarponi da montagna","href":"/scarpe-trekking/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-trekking/modelli/:marca/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"84","breadcrumb":[{"text":"Scarpe trekking & Scarponi da montagna","href":"/scarpe-trekking/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-trekking/outlet/tutti/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"84","breadcrumb":[{"text":"Scarpe trekking & Scarponi da montagna","href":"/scarpe-trekking/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-trekking/outlet/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"84","breadcrumb":[{"text":"Scarpe trekking & Scarponi da montagna","href":"/scarpe-trekking/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-trekking/*/amp/prezzi/tutti/*',
				component:ProductPrices,
				props:{"prodTypeId":"84","breadcrumb":[{"text":"Scarpe trekking & Scarponi da montagna","href":"/scarpe-trekking/modelli/tutti/"}]},
				meta:{"amp":true}
			},
			{
				path:'/scarpe-trekking/*/prezzi/tutti/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"84","breadcrumb":[{"text":"Scarpe trekking & Scarponi da montagna","href":"/scarpe-trekking/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-trekking/*/amp/prezzi/:tienda/*',
				component:ProductPrices,
				props:{"prodTypeId":"84","breadcrumb":[{"text":"Scarpe trekking & Scarponi da montagna","href":"/scarpe-trekking/modelli/tutti/"}]},
				meta:{"amp":true}
			},
			{
				path:'/scarpe-trekking/*/prezzi/:tienda/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"84","breadcrumb":[{"text":"Scarpe trekking & Scarponi da montagna","href":"/scarpe-trekking/modelli/tutti/"}]}
			},
			{
				path:'/scarpe-trekking/*index.amp.html',
				component:ProductPag,
				props:{"prodTypeId":"84"},
				meta:{"amp":true}
			},
			{
				path:'/scarpe-trekking/*',
				component:ProductPag,
				props:{"prodTypeId":"84"}
			},
			{
				path:'/scarponi-montagna/*/tag/:pag(\\d+)?',
				component:TagPage,
				props:{"typeId":"1041"}
			},
			{
				path:'/scarponi-montagna/confronto/',
				component:ComparatorPag,
				props:{"prodTypeId":"1041"}
			},
			{
				path:'/scarponi-montagna/comparazione/:prd1/:prd2/*.html',
				component:ComparisonPag,
				props:{"prodTypeId":"1041"}
			},
			{
				path:'/scarponi-montagna/consigli/raccomandazione/*',
				component:InquiryList,
				props:{"prodTypeId":"1041","breadcrumb":[{"text":"Scarponi da montagna","href":"/scarponi-montagna/modelli/tutti/"}],"Type":1}
			},
			{
				path:'/commenti/scarponi-montagna/*.html',
				component:TalkPage,
				props:{"breadcrumb":[{"text":"Comentarios","href":"/commenti/scarponi-montagna/"}]}
			},
			{
				path:'/scarponi-montagna/recensioni/tester/:pag(\\d+)?',
				component:AuthorList,
				props:{"filter":"reviews","prodTypeId":"1041"}
			},
			{
				path:'/scarponi-montagna/recensioni/tester/:autor/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"1041","breadcrumb":[{"text":"Reviews","href":"/recensioni/"}]}
			},
			{
				path:'/scarponi-montagna/recensioni/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"1041","breadcrumb":[{"text":"Reviews","href":"/recensioni/"}]}
			},
			{
				path:'/scarponi-montagna/opinioni/tutti/:pag(\\d+)?',
				component:OpinionList,
				props:{"prodTypeId":"1041","breadcrumb":[{"text":"Opiniones","href":"/opinioni/tutti/"}]}
			},
			{
				path:'/scarponi-montagna/modelli/tutti/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"1041","breadcrumb":[{"text":"Scarponi da montagna","href":"/scarponi-montagna/modelli/tutti/"}]}
			},
			{
				path:'/scarponi-montagna/modelli/:marca/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"1041","breadcrumb":[{"text":"Scarponi da montagna","href":"/scarponi-montagna/modelli/tutti/"}]}
			},
			{
				path:'/scarponi-montagna/outlet/tutti/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"1041","breadcrumb":[{"text":"Scarponi da montagna","href":"/scarponi-montagna/modelli/tutti/"}]}
			},
			{
				path:'/scarponi-montagna/outlet/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"1041","breadcrumb":[{"text":"Scarponi da montagna","href":"/scarponi-montagna/modelli/tutti/"}]}
			},
			{
				path:'/scarponi-montagna/*/amp/prezzi/tutti/*',
				component:ProductPrices,
				props:{"prodTypeId":"1041","breadcrumb":[{"text":"Scarponi da montagna","href":"/scarponi-montagna/modelli/tutti/"}]},
				meta:{"amp":true}
			},
			{
				path:'/scarponi-montagna/*/prezzi/tutti/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"1041","breadcrumb":[{"text":"Scarponi da montagna","href":"/scarponi-montagna/modelli/tutti/"}]}
			},
			{
				path:'/scarponi-montagna/*/amp/prezzi/:tienda/*',
				component:ProductPrices,
				props:{"prodTypeId":"1041","breadcrumb":[{"text":"Scarponi da montagna","href":"/scarponi-montagna/modelli/tutti/"}]},
				meta:{"amp":true}
			},
			{
				path:'/scarponi-montagna/*/prezzi/:tienda/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"1041","breadcrumb":[{"text":"Scarponi da montagna","href":"/scarponi-montagna/modelli/tutti/"}]}
			},
			{
				path:'/scarponi-montagna/*index.amp.html',
				component:ProductPag,
				props:{"prodTypeId":"1041"},
				meta:{"amp":true}
			},
			{
				path:'/scarponi-montagna/*',
				component:ProductPag,
				props:{"prodTypeId":"1041"}
			},
			{
				path:'/reggiseni-sportivi/outlet/tutti/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"58","breadcrumb":[{"text":"Reggiseni sportivi","href":"/reggiseni-sportivi/outlet/tutti/"}]}
			},
			{
				path:'/reggiseni-sportivi/outlet/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"58","breadcrumb":[{"text":"Reggiseni sportivi","href":"/reggiseni-sportivi/outlet/tutti/"}]}
			},
			{
				path:'/leggings-running/outlet/tutti/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"71","breadcrumb":[{"text":"Calzamaglie da running","href":"/leggings-running/outlet/tutti/"}]}
			},
			{
				path:'/leggings-running/outlet/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"71","breadcrumb":[{"text":"Calzamaglie da running","href":"/leggings-running/outlet/tutti/"}]}
			},
			{
				path:'/auricolari-cuffie/outlet/tutti/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"76","breadcrumb":[{"text":"Cuffie","href":"/auricolari-cuffie/outlet/tutti/"}]}
			},
			{
				path:'/auricolari-cuffie/outlet/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"76","breadcrumb":[{"text":"Cuffie","href":"/auricolari-cuffie/outlet/tutti/"}]}
			},
			{
				path:'/attrezzi-fitness/tutti/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"105","breadcrumb":[{"text":"Attrezzi fitness","href":"/attrezzi-fitness/tutti/"}]}
			},
			{
				path:'/attrezzi-fitness/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"105","breadcrumb":[{"text":"Attrezzi fitness","href":"/attrezzi-fitness/tutti/"}]}
			},
			{
				path:'/codici-sconto-running/',
				component:DiscountCodesList
			},
			{
				path:'/calcolatori/calcolatore-di-tempi/',
				component:Calculator
			},
			{
				path:'/calcolatori/calcolatore-di-tempi/maratona/',
				component:CalculatorMarathon
			},
			{
				path:'/calcolatori/calcolatore-di-tempi/mezza-maratona/',
				component:CalculatorHalfMarathon
			},
			{
				path:'/calcolatori/calcolatore-di-tempi/10-km/',
				component:Calculator10Km
			},
			{
				path:'/pagina-non-trovata',
				component:NotFoundPage
			},
			{
				path:'/404.html',
				component:NotFoundPage
			},
			{
				path:'/enlace/',
				component:RedirectingPag
			},
			{
				path:'/*/enlace/',
				component:RedirectingPag
			},
			
      {
        path: '/running-music/list',
        component: AudiosList
      },
      {
        path: '/running-music/playlist/:id',
        component: AudiosPage
      },
      {
        path: '/auth/login',
        component: AuthLogin
      },
      {
        path: '/auth/redirect',
        component: AuthRedirect
      },
      {
        path: '/auth/callback',
        component: AuthCallback
      },
      {
        path: '/auth/data',
        component: AuthData
      },
      {
        path: '/auth/newsletter',
        component: AuthNewsletter
      },
      {
        path: '/auth/remember',
        component: AuthRemember
      },
      {
        path: '/auth/password/:token',
        component: AuthPassword
      },
      {
        path: '/auth/signin',
        component: AuthSignin
      },
      {
        path: '/countries/:country',
        component: CountriesRedirect
      },
      {
        path: '/*',
        component: LandingPag
      }
    ]
  })
}
