import { render, staticRenderFns } from "./_.vue?vue&type=template&id=135dcae0&"
import script from "./_.vue?vue&type=script&lang=js&"
export * from "./_.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppDynamicComponent: require('/furanet/sites/runnea.it/web/htdocs/nuxt/components/AppDynamicComponent.vue').default,BrandFinder: require('/furanet/sites/runnea.it/web/htdocs/nuxt/components/BrandFinder.vue').default,AuthorLeaf: require('/furanet/sites/runnea.it/web/htdocs/nuxt/components/AuthorLeaf.vue').default,ListPagination: require('/furanet/sites/runnea.it/web/htdocs/nuxt/components/ListPagination.vue').default,OpinionCard: require('/furanet/sites/runnea.it/web/htdocs/nuxt/components/OpinionCard.vue').default})
